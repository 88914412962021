import React, { Fragment } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo'
import { SignUpForm } from '../components/Account';
import CTASection from '../components/CTA Section'

import { ContentWrapper, Header } from '../components/Elements'
import { Grid } from 'semantic-ui-react'

const SignUpPage = () => (
  <Fragment>
    <ContentWrapper>
      <Grid centered columns={2} verticalAlign='middle' style={{ fontFamily: 'Playfair Display'}}>
        <Grid.Row>
          <Grid.Column largeScreen={8} computer={8} tablet={10} mobile={14} padded='vertically'>
            <Header title='true' spaced='true'>Rejestracja</Header>
            <SignUpForm />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ContentWrapper>
  </Fragment>
)

export default () => (
  <Layout>
    <SEO title='Rejestracja' />
      <SignUpPage />
    <CTASection />
  </Layout>
)